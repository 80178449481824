@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.cdnfonts.com/css/coral-reef");
@import url("https://db.onlinewebfonts.com/c/e313dfb6f0d4c85cabeac18e264e5f5e?family=NN+Nouvelle+Grotesk+STD");

.header {
  background: linear-gradient(
      rgba(14, 20, 31, 0.712),
      rgba(26, 167, 233, 0.589)
    ),
    url(../public/imgs//main.jpg) center/cover no-repeat;

  h1,
  h2 {
    text-shadow: 0px 2px 15px rgba(2, 2, 2, 0.582);
  }
}

.cup-banner {
  background: linear-gradient(rgba(6, 10, 7, 0.767), rgba(9, 9, 19, 0.637)),
    url(../public/imgs/2024/5.jpg) center/cover no-repeat;
  &:hover {
    background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
      url(../public/imgs/2024/5.jpg) center/cover no-repeat;
  }
}

.main1,
.gallery {
  background: linear-gradient(rgba(14, 20, 31, 0.712), rgba(7, 17, 22, 0.589)),
    url(../public/imgs/main11.jpg) center/cover no-repeat;
  background-attachment: fixed;
}

.foto-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1.5fr));
  grid-gap: 10px;

  img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
  }
}

.about {
  background: linear-gradient(rgba(14, 20, 31, 0.651), rgba(7, 17, 22, 0.541)),
    url(../public/imgs/about.webp) center/cover no-repeat;
  background-attachment: fixed;
}

.events {
  background: linear-gradient(rgba(0, 0, 0, 0.322), rgba(1, 1, 2, 0.356)),
    url(../public/imgs/2024/1.jpg) center/cover no-repeat;
}

.foto {
  background: linear-gradient(rgba(6, 10, 7, 0.712), rgba(9, 9, 19, 0.589)),
    url(../public/imgs/2024/9.jpg) center/cover no-repeat;
  &:hover {
    background: linear-gradient(rgba(6, 10, 7, 0.63), rgba(9, 9, 19, 0.479)),
      url(../public/imgs/2024/9.jpg) center/cover no-repeat;
  }
}
.results {
  background: linear-gradient(rgba(14, 20, 31, 0.582), rgba(7, 17, 22, 0.479)),
    url(../public/imgs/cups.jpg) bottom/cover no-repeat;
}

.about-img {
  clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
}

.main-p {
  clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

.edicioni {
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}

@media screen and (min-width: 925px) {
  .about-img {
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
